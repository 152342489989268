<template>
	<div class="groupIcon" :aria-labelledby="`labelfor-${stepName}`" role="navigation">
		
		<div class="iconWrapper" aria-hidden="true">
			<!-- <img class="icon" :src="getUrl(stepName)" alt=""> -->

			<!--<icons-svg icon="info" class="icon" v-if="stepName === 'info'" ></icons-svg>
			<icons-svg icon="registration" class="icon" v-if="stepName === 'registration'" ></icons-svg>
			<icons-svg icon="store" class="icon" v-if="stepName === 'store'" ></icons-svg>
			<icons-svg icon="ticket" class="icon" v-if="stepName === 'ticket'" ></icons-svg>
			<icons-svg icon="form" class="icon" v-if="stepName === 'form'" ></icons-svg>
			<icons-svg icon="survey" class="icon" v-if="stepName === 'survey'" ></icons-svg>
			<icons-svg icon="summary" class="icon" v-if="stepName === 'summary'" ></icons-svg>
			<icons-svg icon="cashback" class="icon" v-if="stepName === 'cashback'" ></icons-svg>
			<icons-svg icon="gift" class="icon" v-if="stepName === 'gift'" ></icons-svg>

			<span v-if="isValid" class="pastille valid">
				<svg version="1.1" id="bold" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
				<path class="path_bg" d="M32,0.5C14.7,0.5,0.5,14.7,0.5,32S14.7,63.5,32,63.5S63.5,49.4,63.5,32S49.4,0.5,32,0.5z" />
				<path class="path_txt" d="M28.7,44c-0.9,0-1.7-0.3-2.3-0.9l-9.5-9.1c-0.6-0.6-1-1.4-1-2.2c0-0.8,0.3-1.6,1-2.2c1.3-1.2,3.3-1.2,4.6,0
				l7.2,6.9l16.7-16.1c0.6-0.6,1.4-0.9,2.3-0.9s1.7,0.3,2.3,0.9s1,1.4,1,2.2c0,0.8-0.3,1.6-1,2.2L31,43.1C30.4,43.7,29.6,44,28.7,44z" />
        </svg>
      </span>-->

		  <span class="pastille">{{stepNumber + 1}}</span> <span class="label" :id="`labelfor-${stepName}`">{{$t('breadcrumbSteps.' + stepName)}}</span>

    </div>

	<!--<span class="label" :id="`labelfor-${stepName}`">{{$t('breadcrumbSteps.' + stepName)}}</span>-->

</div>
</template>

<!-- ================================================================================== -->

<script>
	//import IconsSvg from '@/components/IconsSvg';

	export default {
		name:"Crumbs",
		components: {
			//IconsSvg,
		},
		props: {
			stepName : {
				type: String,
				required: true
			},
			stepNumber : {
				type: Number,
				required: true
			},
			isValid : {
				type: Boolean,
				required: true
			}
		},
		methods: {
			getUrl(name) {
				return require('@/assets/img/ico_' + name + '.svg');
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	$small_threshold : 560px;
	$medium_threshold : 860px;
	$big_threshold : 1040px;

	.groupIcon {
		display: flex;
		gap:14px;
		margin:0 auto;

		@media screen and (min-width : $small_threshold) {
			width:300px;
		}

		.iconWrapper {
			position: relative;
		}
		.icon {
			margin: 0 auto;
		}
    span.label {
      margin-left: 10px;
    }

		@media screen and (max-width : $small_threshold) {
			margin-bottom:1em;
			flex-direction: column;
      text-align: left;
			.icon {
				width:45px;
				height:45px;
			}
			span.label {
				font-size:12px;
				max-width:100%;
			}
		}
		@media screen and (min-width : $small_threshold) {
			flex-direction: row;
			text-align: left;
			margin-bottom:1.5em;
			.iconWrapper {
				margin-right:1.5em;
			}
			.icon {
				width:65px;
				height:65px;
			}
			span.label {
				font-size:1em;
			}

		}
		@media screen and (min-width : $medium_threshold) {
			flex-direction: column;
			text-align: center;
			.icon {}
			span.label {}
			.iconWrapper {
				margin-right:0;
			}
		}
		@media screen and (min-width : $big_threshold) {
			.icon {}
			span.label {}
		}

	}

	.label {
		justify-self: start;
		align-self: center;
		line-height:1.2em;
    font-family: 'gibson_semiBold';
		@media screen and (max-width : $small_threshold) {
			display: inline-block;
			margin-top:0.5em;
		}
		@media screen and (min-width : $small_threshold) {
			padding:0;
		}
	}

	span.pastille {
    //position: absolute;
    /* bottom: -2px; */
    padding: 6px !important;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 400px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    left: 0px;
    font-family: 'gibson_bold', 'Trebuchet MS', Arial, Helvetica, sans-serif;
		@media screen and (max-width : $small_threshold) {
			//right:50%;
			//transform: translateX(50px);
		}
		@media screen and (min-width : $small_threshold) {
			//right:50%;
			//transform: translateX(40px);
		}
	}

	span.pastille.valid {
		background: transparent;
	}
	
</style>
